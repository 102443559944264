import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#00aeef",
    paper: "#FAFAFA",
  },
  primary: {
    main: "#00aeef",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#006de3",
  },
  error: {
    main: "#00aeef",
  },
  info: {
    main: "#6E6E73",
  },
  warning: {
    main: "#6E6E73",
  },
  success: {
    main: "#28B67A",
  },
  text: {
    primary: "#000000",
    secondary: "#000000",
  },
};

export default palette;
